.sideMenuItems-group-label {
	display: flex;
	align-items: flex-end;
	height: 60px;
	padding: 10px;
}

.sideMenuItems-paths {
	border-bottom: 1px solid #f2f2f2 !important;
	display: flex !important;
	align-items: center;
	width: 100%;
	padding: 0 !important;
}

.sideMenuItems-path {
	min-height: 36px !important;
	align-items: center;
	width: 100%;
}

.sideMenuItems-path-label {
	text-decoration: none;
	padding: 8px 5px;
	margin-left: 10px;
}
