.customHeader-main {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 0 15px;
	min-height: 80px !important;
}

.account-menu-left-icon {
	height: 20px;
	width: 20px;
}

.account-menu-right-icon {
	height: 20px;
	width: 20px;
}

.account-menu-text-container {
	display: flex !important;
	flex-direction: column !important;
	flex-wrap: wrap;
}

.account-menu-main-container {
	display: flex;
	flex-direction: column;
	padding: 8px;
	gap: 10px;
	min-width: 200px;
}
