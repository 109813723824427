html {
	height: 100%;
}

body {
	margin: 0;
	font-family: "Inter", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
	width: 100%;
	overflow-y: hidden;
}

p {
	margin: 0;
}

#root {
	height: 100%;
}
