.main-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow-y: hidden;
}

.customRouter-main-container {
	display: flex;
	flex-direction: column;
	padding: 2rem 1rem;
	width: 100%;
	margin: 0 auto;
	overflow-y: scroll;
}
