.dialog-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
	width: 100%;
}

.dialog {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
	width: 100%;
}

.dialog .dialog-header {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	background: #f5f5f5;
	box-sizing: border-box;
	padding: 8px 32px;
}

.dialog-header .button {
	text-transform: none;
	padding: 8px;
	border-radius: 5px;
}

.dialog-body-text {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 10px;
}

.dialog-footer {
	display: flex;
	align-items: center;
	width: 100%;
	box-sizing: border-box;
	padding: 24px 32px !important;
	gap: 24px;
	background: #f5f5f5;
	border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.dialog-footer .cancel-button {
	padding: 16px 40px;
	box-shadow: none;
	text-transform: none;
	border-radius: 5px;
	width: 100%;
}

.dialog-footer .submit-button {
	padding: 16px 40px;
	text-transform: none;
	border-radius: 5px;
	width: 100%;
}

.dialog-header-text {
	display: flex;
	flex-direction: column;
	align-self: center;
	flex-grow: 1;
}

.dialog-header-buttonOrState {
	display: flex;
	flex-direction: column;
	align-self: center;
}
