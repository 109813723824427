.display-section-container {
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin: 0 !important;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2) !important;
}

.display-section-summary {
	width: 100%;
	height: 64px !important;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 24px !important;
	margin: 0 !important;
}

.display-section-details {
	width: calc(100% - 32px) !important;
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding: 16px !important;
	margin: 0 !important;
}

.display-section-container .display-info-container,
.display-action-container {
	width: 100%;
	display: flex;
	align-items: center;
	border-radius: 10px;
}

.display-loading-icon-style {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding-top: 75px;
	gap: 10px;
}
