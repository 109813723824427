.auto-complete-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.auto-complete-column {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 8px;
	width: 100%;
}

.auto-complete-row {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	width: 100%;
}
