.custom-banner-title {
	width: 100%;
	margin: 0 !important;
}

.custom-banner {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-radius: 0;
	padding: 8px 24px !important;
}

.custom-banner .content {
	display: flex;
	width: 100%;
	gap: 8px;
}

.custom-banner .content .text-and-logo-container {
	display: flex;
	align-items: center;
	flex-grow: 1;
}

.custom-banner .content .action-button-container {
	display: flex;
	align-items: center;
	gap: 16px;
}

.custom-toast .content .action-button-container {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 16px;
	width: 25%;
}

.custom-banner-action-button {
	padding: 8px 16px !important;
	text-transform: none !important;
	box-shadow: none !important;
}

.custom-banner-close-button {
	padding: 8px !important;
	text-transform: none !important;
}

.custom-toast {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-radius: 0 !important;
	padding: 6px 16px !important;
	height: 48px;
}

.custom-toast .toast-alert {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	min-width: 260px;
	padding: 0 16px !important;
	gap: 8px;
	border-radius: 4px;
}

.toast-alert .content {
	display: flex;
	align-items: center;
	width: 100%;
	gap: 8px;
}

.custom-toast .content .text-and-logo-container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 75%;
}

@media (max-width: 600px) {
	.custom-banner .content {
		flex-direction: column;
	}
}
