.timeChoice-outerContainer {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.timeChoice-innerContainer {
	display: flex;
	flex-direction: column;
	max-width: 900px;
	width: 100%;
	gap: 30px;
}

.timeChoice-workTime-outerContainer {
	display: flex;
	gap: 10px;
	width: 100%;
	flex-wrap: wrap;
}

.timeChoice-workTime-choiceContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	box-sizing: border-box;
	cursor: pointer;
	transition: 0.3s;
	user-select: none;
}

.timeChoice-workTime-choiceContainer:hover {
	background-color: #565454;
}

.timeChoice-submit-container {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.contactInformation-outerContainer {
	display: flex;
	justify-content: center;
	gap: 20px;
	width: 80%;
	padding: 15px 10%;
}

.successfulRegistrationView-infoContainer {
	display: flex;
	width: 100%;
}
