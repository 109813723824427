.accountPage-mainContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-bottom: 20px;
	gap: 2rem;
}

.accountPage-returnActionContainer {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.accountPage-sectionsContainer,
.accountPage-actionContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 2rem;
}

.accountPage-centeredContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}
