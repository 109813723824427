.filter-form-main-container {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	gap: 16px;
	padding-top: 1rem;
}

.search-form-container {
	display: flex;
}

.search-button-container {
	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: flex-end;
	align-items: flex-end;
	max-width: 700px;
	width: 100%;
}

.customForm-actionContainer {
	display: flex;
	align-items: center;
	width: 100%;
	gap: 10px;
}

.codeValidationForm-alert {
	display: flex;
	align-items: center;
}

.codeValidationForm-form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: justify;
	gap: 10px;
	width: 100%;
}

.commentSection-container {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.commentSection-title {
	width: 100%;
	padding-bottom: 10px;
}

.commentSection-display-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	border: 1px solid black;
	max-height: 400px;
	border-radius: 5px;
}

.commentSection-display-comments-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	padding: 10px;
}

.commentSection-display-comments {
	width: 100%;
	display: flex;
	flex-direction: column;
	flex: 1 1 400px;
	gap: 15px;
	justify-content: flex-end;
}

.commentSection-individualComment-container {
	width: calc(100% - 20px);
	display: flex;
	align-items: flex-start;
}

.commentSection-individualComment-text {
	max-width: 50%;
	display: flex;
	flex-direction: column;
	border-radius: 15px;
	padding: 5px 10px 10px;
	gap: 5px;
	background-color: #7676ff;
}

.commentSection-individualComment-text-row {
	display: flex;
	gap: 5px;
	align-items: center;
	justify-content: space-between;
}
